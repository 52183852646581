(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.AssetManagerServices = f()}})(function(){var define,module,exports;return (function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.switchPopulatedObjects = exports.setToken = exports.setForB2B = exports.resetUrl = exports.removeFromPinboard = exports.removeFromAssetsGroupsList = exports.postTemplatePreview = exports.modifyTemplate = exports.loadPopulatedObjects = exports.isUserAllowedToManageAssetsGroupsList = exports.isKitchenEntityWithPlinths = exports.isForB2B = exports.initCountry = exports.init = exports.getUrlAssets = exports.getUrl = exports.getToken = exports.getTemplates = exports.getTagsMatching = exports.getSwitchSuggestions = exports.getSmartDesignerUrl = exports.getResourcesPinboards = exports.getResourcesAssetsGroupsList = exports.getPinboards = exports.getParamsList = exports.getMediaUrl = exports.getMediaArrangementUrl = exports.getIxinaKitchenPrice = exports.getHandles = exports.getFrontUrl = exports.getCoatings = exports.getCoatingTypes = exports.getCoating = exports.getAssetsGroupsLists = exports.getAssetsFiltered = exports.getAssets = exports.getAssetGroupByName = exports.getAsset = exports.getApiKey = exports.editPinboard = exports.editAssetsGroupsList = exports.deletePinboard = exports.deleteAssetsGroupsList = exports.createPinboard = exports.createAssetsGroupsList = exports.createAssetEntity = exports.commitTemplate = exports.changeKitchenStyle = exports.addToPinboard = exports.addToAssetsGroupsList = exports._ROUTE_PARAMS = exports._ASSET_TYPE = exports.EVENTS = exports.ARRMGR_URL = exports.ARRMGR_TOKEN = exports.ARRMGR_MEDIA_URL = exports.ARRMGR_FRONT_URL = exports.ARRMGR_FOR_B2B = exports.ARRMGR_COUNTRY_PARAM = exports.ARRMGR_COUNTRY_ID = exports.ARRMGR_API_KEY = void 0;
/**
 * Created by rhinov25 on 02/02/2017.
 *
 * Asset manager calls using web services, this is used by designer filters module
 */

// All asset types within the asset manager
var _ASSET_TYPE = exports._ASSET_TYPE = {
  ARRANGEMENTS: 'assets',
  COATINGS: 'coatings',
  TEMPLATES: 'assets_groups',
  TAGS: 'tags',
  JOINERIES: 'joineries',
  TECHNICAL_ELEMENTS: 'technicalElements',
  DECORATED_WALLS: 'decoratedwalls',
  ALL: 'allAssets'
};

// Routes to access asset sub types
var _ROUTE_PARAMS = exports._ROUTE_PARAMS = {
  ROOM_TYPES: 'roomtypes',
  OBJECT_TYPES: 'objecttypes',
  STYLES: 'styles',
  TAGS: 'tags',
  JOINERY_TYPES: 'joinerytypes',
  TECHNICAL_ELEMENT_TYPES: 'technicalelementtypes',
  DECORATED_WALL_TYPES: 'decoratedwalltypes'
};

// Asset creation event broadcasted from the asset manager loader
var EVENTS = exports.EVENTS = {
  ARRANGEMENT_CREATED: 'assetmanager_arrangement_created'
};

// Path to assets
var ARRMGR_URL = exports.ARRMGR_URL = '//assetmanager.rhinovplanner.com/';
var ARRMGR_TOKEN = exports.ARRMGR_TOKEN = '$2a$10$H/D4EyQxl1myXixtuuvAT.Sc1nG.mUT8Mmnfmp9xI1GeYWOTwqzqC';
var ARRMGR_API_KEY = exports.ARRMGR_API_KEY = 'apikey=' + ARRMGR_TOKEN;
var ARRMGR_COUNTRY_PARAM = exports.ARRMGR_COUNTRY_PARAM = '&q.locales.fr_FR.availability.eq=fully_available';
var ARRMGR_COUNTRY_ID = exports.ARRMGR_COUNTRY_ID = 0;
var ARRMGR_FRONT_URL = exports.ARRMGR_FRONT_URL = '//assetmanager.rhinovplanner.com/';
var ARRMGR_MEDIA_URL = exports.ARRMGR_MEDIA_URL = '//media.rhinov.fr/';
var ARRMGR_FOR_B2B = exports.ARRMGR_FOR_B2B = false;
var init_to_cloud = function init_to_cloud(media) {
  if (!media) {
    // Fail to connect, switch to cloud asset manager only if prod
    var index = ARRMGR_URL.indexOf("assetmanager.rhinov.fr");
    if (index !== -1) {
      exports.ARRMGR_URL = ARRMGR_URL = ARRMGR_URL.slice(0, index) + 'cloud-' + ARRMGR_URL.slice(index);
      void 0;
    }
  } else {
    exports.ARRMGR_MEDIA_URL = ARRMGR_MEDIA_URL = ARRMGR_URL + 'api/v1/';
  }
};

/**
 * reset asset manager url ,token
 */
var init = exports.init = function init(url, front_url, media_url, token) {
  exports.ARRMGR_URL = ARRMGR_URL = url;
  exports.ARRMGR_FRONT_URL = ARRMGR_FRONT_URL = front_url;
  exports.ARRMGR_MEDIA_URL = ARRMGR_MEDIA_URL = media_url;
  exports.ARRMGR_TOKEN = ARRMGR_TOKEN = token;
  exports.ARRMGR_API_KEY = ARRMGR_API_KEY = 'apikey=' + ARRMGR_TOKEN;

  // Test AssetManager DB connexion with NAE BLANC
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/coatings/5d4a9f07fcef3b63c55e79d5?' + ARRMGR_API_KEY).then(function (response) {}, function (error) {
    // Fail to connect, switch to cloud asset manager only if prod
    init_to_cloud(false);
  });

  // Test AssetManager media connexion with NAE BLANC
  var http = new XMLHttpRequest();
  try {
    http.open('HEAD', ARRMGR_MEDIA_URL + 'coatings/5d4a9f07fcef3b63c55e79d5/medias/previews/preview1-sm.webp', false);
    http.send();

    // Success keep current AM URL
    if (http.status !== 200) {
      // Fail to connect, switch to cloud asset manager only if prod
      init_to_cloud(true);
    }
  } catch (e) {
    // Fail to connect, switch to cloud asset manager only if prod
    init_to_cloud(true);
  }
};
var setForB2B = exports.setForB2B = function setForB2B(value) {
  exports.ARRMGR_FOR_B2B = ARRMGR_FOR_B2B = value;
};
var isForB2B = exports.isForB2B = function isForB2B() {
  return ARRMGR_FOR_B2B;
};

/**
 * reset asset manager country
 */
var initCountry = exports.initCountry = function initCountry(customer_groups_id) {
  exports.ARRMGR_COUNTRY_ID = ARRMGR_COUNTRY_ID = customer_groups_id;
  switch (ARRMGR_COUNTRY_ID) {
    case 176:
      exports.ARRMGR_COUNTRY_PARAM = ARRMGR_COUNTRY_PARAM = '&q.locales.it_IT.availability.eq=fully_available';
      break;
    case 175:
      exports.ARRMGR_COUNTRY_PARAM = ARRMGR_COUNTRY_PARAM = '&q.locales.es_ES.availability.eq=fully_available';
      break;
    case 177:
      exports.ARRMGR_COUNTRY_PARAM = ARRMGR_COUNTRY_PARAM = '&q.locales.de_DE.availability.eq=fully_available';
      break;
    default:
      exports.ARRMGR_COUNTRY_PARAM = ARRMGR_COUNTRY_PARAM = '&q.locales.fr_FR.availability.eq=fully_available';
  }
};
void 0;
var setToken = exports.setToken = function setToken(token) {
  exports.ARRMGR_TOKEN = ARRMGR_TOKEN = token;
  exports.ARRMGR_API_KEY = ARRMGR_API_KEY = 'apikey=' + ARRMGR_TOKEN;
};

/**
 * getter for conf
 */
var getUrl = exports.getUrl = function getUrl() {
  return ARRMGR_URL;
};
var getFrontUrl = exports.getFrontUrl = function getFrontUrl() {
  return ARRMGR_FRONT_URL;
};
var getMediaUrl = exports.getMediaUrl = function getMediaUrl() {
  return ARRMGR_MEDIA_URL;
};
var getMediaArrangementUrl = exports.getMediaArrangementUrl = function getMediaArrangementUrl() {
  if (ARRMGR_MEDIA_URL.indexOf("media") === -1) {
    return ARRMGR_MEDIA_URL + "assets/";
  } else {
    return ARRMGR_MEDIA_URL + "arrangements/";
  }
};
var getUrlAssets = exports.getUrlAssets = function getUrlAssets() {
  return ARRMGR_URL + 'api/v1/assets/';
};
var getToken = exports.getToken = function getToken() {
  return ARRMGR_TOKEN;
};
var getApiKey = exports.getApiKey = function getApiKey() {
  return ARRMGR_API_KEY;
};
var getSmartDesignerUrl = exports.getSmartDesignerUrl = function getSmartDesignerUrl() {
  return ARRMGR_URL;
};

/**
 *  Generic function to retrieve list of asset attributes
 *
 * @param {String} paramType
 * @param {Array} filters
 * @param {Function} callback
 */
var getParamsList = exports.getParamsList = function getParamsList(paramType, data, callback) {
  // Filter to send
  var filters = '';

  // Transform a list of filters sent into the data array to a string with & separator for the web service call
  if (data != null) {
    if (data != undefined) {
      for (var i = 0; i < data.length; i++) {
        filters += '&' + data[i];
      }
    }
  }

  // Call the web service with the composed URL
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/' + paramType + '/?' + ARRMGR_API_KEY + filters).then(function (response) {
    //success
    callback(response);
  }, function (response) {
    //error
    callback(null);
  });
};

/**
 * Retrieve tag match with input
 *
 * @param inputString
 * @param returnCount
 * @param callback
 */
var getTagsMatching = exports.getTagsMatching = function getTagsMatching(inputString, returnCount, callback) {
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/tags?q.name.lk=' + inputString + '&page=1&limit=' + returnCount + '&' + _API_KEY).then(function (response) {
    //success
    callback(response);
  }, function (response) {
    //error
    callback(null);
  });
};
var getIxinaKitchenPrice = exports.getIxinaKitchenPrice = function getIxinaKitchenPrice(ixinaTriplets, callback) {
  var payload = {
    'triplets': ixinaTriplets
  };
  Savane.HttpService.post(ARRMGR_URL + 'api/services/price?' + ARRMGR_API_KEY, payload).then(function (response) {
    //success
    callback(response);
  }, function (response) {
    //error
    callback(response);
  });
};

/**
 *
 * @param assetType
 * @param stringFilters
 * @param callback
 * @returns {*}
 */
var getAssets = exports.getAssets = function getAssets(assetType, stringFilters, callback) {
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/' + assetType + '?' + ARRMGR_API_KEY + stringFilters).then(function (response) {
    if (callback) callback(response, true);
    return response;
  }, function (response) {
    if (callback) callback(response, false);
  });
};

/**
 * Retrieve assets filtered
 *
 * @param assetType
 * @param filters
 * @param page
 * @param limit
 * @param callback
 * @returns {*}
 */
var getAssetsFiltered = exports.getAssetsFiltered = function getAssetsFiltered(assetType, filters, page, limit, callback) {
  // Filter we will send in the web service call
  var filter;
  //Base params with page number and result per page
  var requestParams = 'page=' + page + '&limit=' + limit;

  // Read filter and bind to request format
  for (filter in filters) {
    // Get current filter
    var currentFilter = filters[filter];
    if (currentFilter.key && currentFilter.method && currentFilter.values !== undefined) {
      if (currentFilter.values !== null) {
        //A value set to null mean no filter on that field
        var filterName = currentFilter.filter ? currentFilter.filter : filter;
        //If no filter set use default field name
        var filterConcat = '&q.' + filterName + '.' + currentFilter.key + '.' + currentFilter.method + '=' + currentFilter.values;
        requestParams += filterConcat;
      }
    } else {
      if (currentFilter.values !== null) {
        void 0;
      }
    }
  }

  //Send request
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/' + assetType + '/?' + requestParams + '&' + ARRMGR_API_KEY).then(function (response) {
    if (callback) callback(response);
    return response.data;
  }, function (response) {
    if (callback) callback(response);
  });
};

/**
 * Retrieve asset datas
 * @param assetType
 * @param id
 * @param apiKey
 * @param callback
 * @returns {*}
 */
var getAsset = exports.getAsset = function getAsset(assetType, id, apiKey, callback) {
  var OPTIMIZED_DB_FIELDS = '&fields=name,randomization,isHpFloorGenerator,hpFloorGeneratorSettings,dimensions,sketchBlock,_id,configs,colors,stretchability,objectType,coatingType,hang,technicalElementType,manufacturer,retailer,joineryType,styles,objectTypeConfig,zones,customization,type,rhinovFormat';
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/' + assetType + '/' + id + '?' + OPTIMIZED_DB_FIELDS + '&' + ARRMGR_API_KEY).then(function (response) {
    if (callback) callback(response.data);
  }, function (response) {
    if (callback) callback(response);
  });
};

/**
 * Retrieve asset datas
 * @param assetType
 * @param id
 * @param apiKey
 * @param callback
 * @returns {*}
 */
var getAssetGroupByName = exports.getAssetGroupByName = function getAssetGroupByName(name, callback) {
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/assets_groups?q.name.eq=' + encodeURIComponent(name) + '&' + ARRMGR_API_KEY).then(function (response) {
    if (callback) callback(response);
  }, function (response) {
    if (callback) callback(response);
  });
};

/**
 * Push a new template
 *
 * @param name
 * @param rhinovFormat
 * @param callback
 */
var commitTemplate = exports.commitTemplate = function commitTemplate(name, rhinovFormat, callback) {
  Savane.HttpService.post(ARRMGR_FRONT_URL + 'api/v1/assets_groups?' + ARRMGR_API_KEY, {
    name: name,
    rhinovFormat: rhinovFormat
  }).then(function (response) {
    //success
    callback(response);
  }, function (response) {
    //error
    callback(response);
  });
};
var postTemplatePreview = exports.postTemplatePreview = function postTemplatePreview(templateId, blob, callback) {
  var fd = new FormData();
  fd.append('file', blob);
  Savane.HttpService.put(ARRMGR_FRONT_URL + 'api/v1/assets_groups/' + templateId + "/medias/previews/preview.jpg?" + ARRMGR_API_KEY, fd, {
    transformRequest: angular.identity,
    headers: {
      'Content-Type': undefined
    }
  }).then(function (response) {
    //successCallback
    callback(response);
  }, function (response) {
    //error
    callback(response);
  });
};

/**
 * Modify a template
 *
 * @param name
 * @param rhinovFormat
 * @param callback
 */
var modifyTemplate = exports.modifyTemplate = function modifyTemplate(id, name, rhinovFormat, callback) {
  Savane.HttpService.patch(ARRMGR_URL + 'api/v1/assets_groups/' + id + '?' + ARRMGR_API_KEY, {
    name: name,
    rhinovFormat: rhinovFormat
  }).then(function (response) {
    //success
    callback(response);
  }, function (response) {
    //error
    callback(response);
  });
};

/**
 * Return all coating types
 * @param {string[]} filters
 * @param {function} callback
 */
var getCoatingTypes = exports.getCoatingTypes = function getCoatingTypes(filters, callback) {
  var params = '';
  if (filters !== null) {
    for (var i = 0; i < filters.length; i++) {
      if (i > 0) {
        params += '&';
      }
      params += filters[i];
    }
    params += '&';
  }
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/coatingTypes?' + params + ARRMGR_API_KEY).then(function (response) {
    callback(response);
  });
};

/**
 * Return all coatings
 * @param {string[]} filters
 * @param {function} callback
 */
var getCoatings = exports.getCoatings = function getCoatings(filters, callback) {
  var params = '';
  if (filters !== null) {
    for (var i = 0; i < filters.length; i++) {
      if (i > 0) {
        params += '&';
      }
      params += filters[i];
    }
    params += '&';
  }
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/coatings?' + params + ARRMGR_API_KEY).then(function (response) {
    callback(response);
  });
};

/**
 * Return one coating
 * @param {string} id
 * @param {function} callback
 */
var getCoating = exports.getCoating = function getCoating(id, callback) {
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/coatings/' + id + '?' + ARRMGR_API_KEY).then(function (response) {
    callback(response);
  });
};
var getHandles = exports.getHandles = function getHandles(filter, objectType, callback) {
  /*Savane.HttpService.get(ARRMGR_URL + 'api/v1/assets?q.objectType._id.eq=58933a3992143406c482fb2b&' + filter + '&' + ARRMGR_API_KEY)
      .then(function (response) {
          callback(response);
      });*/
  Savane.HttpService.get(ARRMGR_URL + 'api/v1/assets?q.objectType._id.eq=' + objectType + '&' + filter + '&' + ARRMGR_API_KEY).then(function (response) {
    callback(response);
  });
};
var isKitchenEntityWithPlinths = exports.isKitchenEntityWithPlinths = function isKitchenEntityWithPlinths(entity) {
  if (entity.entityType !== Savane.SceneConstants.EntityType.ArrangementObject) {
    return false;
  }
  if (entity.objectType === Savane.SceneConstants.ArrangementType.kitchenFurnitureBottom || entity.objectType === Savane.SceneConstants.ArrangementType.kitchenFurnitureColumn) {
    return true;
  } else {
    return false;
  }
};
var resetUrl = exports.resetUrl = function resetUrl(arrangement, url) {
  var idx = url.indexOf("coatings");
  if (idx !== -1) {
    return AssetManagerServices.getMediaUrl() + "coatings/" + arrangement.objectId + "/medias/previews/preview" + arrangement.colorId + "-sm.webp";
  } else {
    return AssetManagerServices.getMediaArrangementUrl() + arrangement.objectId + "/medias/previews/top_view" + arrangement.colorId + ".webp";
  }
};

//
// Change an object list (of kitchen objects to another kitchen style
//
var changeKitchenStyle = exports.changeKitchenStyle = function changeKitchenStyle(entityList, newStyle, newDoorColor, callback) {
  var allObjectsIds = entityList.map(function (item) {
    return item._objectId;
  });
  var toReturn = [];
  AssetManagerServices.getAssets(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, '&fields=_id,pipeline.originalModel,objectTypeConfig&q._id.in=' + allObjectsIds, function (result) {
    var originalModelsList = [];
    var resourcesAM = result.data.resources;
    for (var i = 0; i < allObjectsIds.length; i++) {
      var idx = resourcesAM.findIndex(function (x) {
        return x._id === allObjectsIds[i];
      });

      // idx === -1 impossible here, the ressources must be found whatever happens

      // AM ressource has an original model ?
      if (resourcesAM[idx].pipeline.originalModel) {
        // Yes store it
        originalModelsList.push(resourcesAM[idx].pipeline.originalModel._id);
        toReturn.push({
          current_object_id: allObjectsIds[i],
          original_model_id: resourcesAM[idx].pipeline.originalModel._id,
          new_object_id: null,
          new_object_entity: null,
          filled: false
        });
      } else {
        // No, then the original model is himself
        originalModelsList.push(allObjectsIds[i]);
        toReturn.push({
          current_object_id: allObjectsIds[i],
          original_model_id: allObjectsIds[i],
          new_object_id: null,
          new_object_entity: null,
          filled: false
        });
      }
    }
    var search = '&q.pipeline.originalModel.in=';
    if (newStyle === 'CU_blank') {
      search = '&q._id.in=';
    }
    AssetManagerServices.getAssets(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, '&fields=kind,_id,pipeline.originalModel,marketName,price,genericName,name,type,randomization,dimensions,displayDimensions,sketchBlock,marketLink,configs,colors,stretchability,objectType,manufacturer,retailer,rse,pipeline.state,pipeline.modelState,styles,objectTypeConfig,zones,customization,universes,pipeline.unrealState' + search + originalModelsList + '&q.objectTypeConfig.doorType.eq=' + newStyle, function (result) {
      var resourcesAM2 = result.data.resources;
      for (var j = 0; j < toReturn.length; j++) {
        var idx = resourcesAM2.findIndex(function (x) {
          if (x.pipeline.originalModel) {
            return toReturn[j].original_model_id === x.pipeline.originalModel._id;
          } else {
            return toReturn[j].original_model_id === x._id;
          }
        });

        // Original model id found in AM ressources ?
        if (idx !== -1) {
          // Yes store it
          toReturn[j].new_object_id = resourcesAM2[idx]._id;
        } else {
          // No consider the ressource is itself
          toReturn[j].new_object_id = toReturn[j].original_model_id;
          toReturn[j].filled = true;
        }
      }
      for (var j = 0; j < toReturn.length; j++) {
        var idx = resourcesAM2.findIndex(function (x) {
          return x._id === toReturn[j].new_object_id;
        });

        // No ressource found to replace the original ressource, cintinue loop
        if (idx === -1) {
          continue;
        }

        // Set coating default values for further entity creation
        if (typeof EntityDefaultPropertiesManager !== 'undefined') {
          var entity = entityList[j];
          var components = entity.getComponents(Savane.ComponentConstants.ComponentType.Coating);
          for (var k = 0; k < components.length; k++) {
            if (components[k].hangType !== Savane.Coating.HangType.door) {
              EntityDefaultPropertiesManager.getInstance().setKitchenDefaultCoatingByHangtype(components[k].hangType, components[k].coatingId);
            } else {
              if (newDoorColor) {
                EntityDefaultPropertiesManager.getInstance().setKitchenDefaultCoatingByHangtype(components[k].hangType, newDoorColor);
              } else {
                EntityDefaultPropertiesManager.getInstance().setKitchenDefaultCoatingByHangtype(components[k].hangType, components[k].coatingId);
              }
            }
          }
          if (entity.handleAsset) {
            EntityDefaultPropertiesManager.getInstance().setKitchenDefaultHandle(entity.handleAsset);
          }
        }

        // Transform AM ressource to entity savane
        resourcesAM2[idx].handleSide = entityList[idx].handleSide;
        AssetManagerServices.createAssetEntity(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, resourcesAM2[idx], true, function (entity) {
          var idx = toReturn.findIndex(function (x) {
            return x.new_object_id === entity.objectId && x.new_object_entity === null;
          });
          toReturn[idx].new_object_entity = entity;
          toReturn[idx].filled = true;
          for (var l = 0; l < toReturn.length; l++) {
            // End or not end of entity creations ?
            if (!toReturn[l].filled) {
              break;
            }
          }
          if (l === toReturn.length) {
            callback(toReturn);
          }
        });
      }
    });
  });
};

/*function resetAnchorActive(children) {
    for (var i = 0; i < children.length; ++i) {
        children[i].anchorActive = true;
        resetAnchorActive(children[i].children);
    }
}*/

var createAssetEntity = exports.createAssetEntity = function createAssetEntity(assetType, selectedAsset, loadExtraAssets, callback) {
  if (!selectedAsset || !selectedAsset._id) {
    return;
  }
  if (assetType === _ASSET_TYPE.TEMPLATES) {
    var format = selectedAsset.rhinovFormat;
    if (!format.children) {
      return;
    }
    //        resetAnchorActive(format.children);

    // This is done to renumber the entity tree of the arrangement group import : keep last entity id, import the group then reset the last entity id just before cloning the arrangement group tree
    var currentLastEntityID = Savane.EntityFactory.getLastEntityId();
    var arrangementGroup = Savane.EntityFactory.createArrangementGroupFromJSON(format);
    Savane.EntityFactory.setLastEntityId(currentLastEntityID);
    arrangementGroup = Savane.EntityFactory.cloneArrangementGroup(arrangementGroup, true);
    arrangementGroup.recenter();
    arrangementGroup.objectId = selectedAsset._id;
    arrangementGroup.isAnchorActive = false;
    callback(arrangementGroup);
  } else if (assetType === _ASSET_TYPE.ARRANGEMENTS || assetType === _ASSET_TYPE.TECHNICAL_ELEMENTS || assetType === _ASSET_TYPE.JOINERIES || assetType === _ASSET_TYPE.COATINGS || assetType === _ASSET_TYPE.DECORATED_WALLS) {
    var i,
      coating = null;
    if (selectedAsset.configs !== undefined && selectedAsset.configs !== null) {
      for (i = 0; i < selectedAsset.configs.length; i++) {
        if (selectedAsset.choosenIndex === selectedAsset.configs[i].index) {
          coating = selectedAsset.configs[i].coating !== null && selectedAsset.configs[i].coating !== undefined && selectedAsset.configs[i].coating.item !== null && selectedAsset.configs[i].coating.item !== undefined && selectedAsset.configs[i].coating.item._id.length > 0 ? selectedAsset.configs[i].coating.item._id : null;
          break;
        }
      }
    }

    // Entity created and to return
    var entity;
    switch (assetType) {
      case _ASSET_TYPE.ARRANGEMENTS:
        var URL = getMediaArrangementUrl() + selectedAsset._id + '/medias/previews/top_view1.webp';
        var choosenIndex = 1;
        if (selectedAsset.choosenIndex !== undefined) {
          URL = getMediaArrangementUrl() + selectedAsset._id + '/medias/previews/top_view' + selectedAsset.choosenIndex + '.webp';
          choosenIndex = selectedAsset.choosenIndex;
        }

        // Build customization for kitchen element, dressing elements, bathroom elements (for instance)
        var customization = {
          parts: [],
          expectedParts: []
        };
        if (selectedAsset.customization !== undefined) {
          // Save customization parts
          if (selectedAsset.customization.parts) {
            for (i = 0; i < selectedAsset.customization.parts.length; i++) {
              var type = selectedAsset.customization.parts[i].name.split('_')[2];
              var id_coating = selectedAsset.customization.parts[i].id_coating;
              if (id_coating === undefined || id_coating === '') {
                if (typeof EntityDefaultPropertiesManager === 'undefined') {
                  id_coating = '664e0d00b751b3c903290f2c';
                } else {
                  if (type === "Porte") {
                    for (var j = 0; j < selectedAsset.customization.expectedParts.length; j++) {
                      if (selectedAsset.customization.expectedParts[j].name === "Porte") {
                        break;
                      }
                    }
                    if (EntityDefaultPropertiesManager.getInstance().isCoatingCompatibleWithDoorCustomizationGroups(selectedAsset.customization.expectedParts[j].groups)) {
                      id_coating = EntityDefaultPropertiesManager.getInstance().getKitchenDefaultCoating(type);
                    } else {
                      id_coating = '664e0d00b751b3c903290f2c';
                    }
                  } else {
                    id_coating = EntityDefaultPropertiesManager.getInstance().getKitchenDefaultCoating(type);
                  }
                }
              }

              // The object has expected parts ()
              if (selectedAsset.customization.expectedParts && selectedAsset.customization.expectedParts.length > 0) {
                // Default white coating for Doors, Cabinet and TopBottom
                /*if (type === 'Caisson' || type === 'Porte' || type === 'HautBas') {
                    selectedAsset.customization.parts[i].id_coating = '5f21626db36b241d008438de';
                }*/
                // Default customizationGroup assignement
                if (type === 'Porte') {
                  for (var j = 0; j < selectedAsset.customization.expectedParts.length; j++) {
                    if (selectedAsset.customization.expectedParts[j].name === type) {
                      for (var k = 0; k < selectedAsset.customization.expectedParts[j].groups.length; k++) {
                        if (selectedAsset.customization.expectedParts[j].groups[k].name === 'TREND +' || selectedAsset.customization.expectedParts[j].groups[k].name === 'TREND CUBE' || selectedAsset.customization.expectedParts[j].groups[k].name === 'TREND + JET' || selectedAsset.customization.expectedParts[j].groups[k].name === 'TREND EDGE') {
                          customization.customizationGroup = selectedAsset.customization.expectedParts[j].groups[k];
                        }
                      }
                    }
                  }
                }
              }
              customization.parts.push({
                name: selectedAsset.customization.parts[i].name,
                id_coating: id_coating
              });
            }
            // Sort Customizations using name key
            customization.parts.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              } else {
                return 1;
              }
            });
          }

          // Save customization expected parts if any
          if (selectedAsset.customization.expectedParts) {
            for (i = 0; i < selectedAsset.customization.expectedParts.length; i++) {
              customization.expectedParts.push(selectedAsset.customization.expectedParts[i]);
            }
            customization.expectedParts.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              } else {
                return 1;
              }
            });
          }
        }
        if (customization.parts.length === 0) {
          customization = undefined;
        }

        // Mouchard
        if (!selectedAsset.objectType) {
          throw "Object with unknown object type " + selectedAsset._id;
        }
        if (!selectedAsset.configs[choosenIndex - 1]) {
          throw "Object with no configs " + selectedAsset._id;
        }
        if (!selectedAsset.configs[choosenIndex - 1].dimensions) {
          throw "Object with no dimensions " + selectedAsset._id;
        }
        if (selectedAsset.configs[choosenIndex - 1].dimensions.width === 0 || selectedAsset.configs[choosenIndex - 1].dimensions.length === 0 || selectedAsset.configs[choosenIndex - 1].dimensions.height === 0) {
          throw "Objet with dimensions set to 0 " + selectedAsset._id;
        }
        // Mouchard

        entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id, selectedAsset.objectType._id, selectedAsset.manufacturer, selectedAsset.retailer, selectedAsset.styles, selectedAsset.configs[choosenIndex - 1].dimensions.width, selectedAsset.configs[choosenIndex - 1].dimensions.length, selectedAsset.configs[choosenIndex - 1].dimensions.height, selectedAsset.choosenIndex, coating, selectedAsset.stretchability, customization, URL);
        if (selectedAsset.objectTypeConfig !== undefined) {
          if (selectedAsset.objectTypeConfig.hasLight) {
            entity.lightOn = false;
            entity.lightOff = false;
            entity.temperature = Savane.SceneConstants.LightTemperature.normal;
          }
          if (selectedAsset.objectTypeConfig.coatingAllowed) {
            entity.coatingAllowed = true;
          } else {
            entity.coatingAllowed = false;
          }
        }
        break;
      case _ASSET_TYPE.COATINGS:
        // Let's find a correct preview for this coating within the medias
        /*            var mediaIdx;
                      // Search for a small preview first
                    for (mediaIdx = 0 ; mediaIdx < selectedAsset.medias.length ; mediaIdx++)
                    {
                        if (selectedAsset.medias[mediaIdx].indexOf("/small/preview" + selectedAsset.choosenIndex + ".") !== -1)
                        {
                            break;
                        }
                    }
                      // If not found, then search any media within the preview folder preview
                    if (mediaIdx === selectedAsset.medias.length)
                    {
                        for (mediaIdx = 0 ; mediaIdx < selectedAsset.medias.length ; mediaIdx++)
                        {
                            if (selectedAsset.medias[mediaIdx].indexOf("/previews/") !== -1)
                            {
                                break;
                            }
                        }
                    }*/

        // Then create the URL of the preview to load
        //              var URL = getUrl() + '.' + selectedAsset.medias[mediaIdx] + '?apikey=' + getToken();
        var URL = getMediaUrl() + 'coatings/' + selectedAsset._id + '/medias/previews/preview' + selectedAsset.choosenIndex + '-sm.webp';
        entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id, selectedAsset.coatingType._id, selectedAsset.manufacturer, selectedAsset.retailer, 0, 800, 800, 800, selectedAsset.choosenIndex, coating, selectedAsset.stretchability, selectedAsset.customization, URL);
        break;
      case _ASSET_TYPE.JOINERIES:
      case _ASSET_TYPE.TECHNICAL_ELEMENTS:
      case _ASSET_TYPE.DECORATED_WALLS:
        // Let's search for the correct asset to display as preview
        var mediaIdx;

        // Search for a small preview first
        for (mediaIdx = 0; mediaIdx < selectedAsset.medias.length; mediaIdx++) {
          if (selectedAsset.medias[mediaIdx].indexOf("preview1-sm.") !== -1) {
            break;
          }
        }

        // If not found, then search for the normal preview
        if (mediaIdx === selectedAsset.medias.length) {
          for (mediaIdx = 0; mediaIdx < selectedAsset.medias.length; mediaIdx++) {
            if (selectedAsset.medias[mediaIdx].indexOf("preview1.") !== -1) {
              break;
            }
          }
        }
        // Then create the URL of the preview to load
        var URL = getUrl() + '.' + selectedAsset.medias[mediaIdx] + '?apikey=' + getToken();

        // Return the correct entity
        switch (assetType) {
          case _ASSET_TYPE.TECHNICAL_ELEMENTS:
            // Create the arrangement object to pass to the DragComponentState
            entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id, selectedAsset.technicalElementType._id, selectedAsset.manufacturer, selectedAsset.retailer, 0, 800, 800, 800, 1, coating, selectedAsset.stretchability, selectedAsset.customization, URL);
            break;
          case _ASSET_TYPE.JOINERIES:
            // Create the arrangement object to pass to the DragComponentState
            entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id, selectedAsset.joineryType._id, selectedAsset.manufacturer, selectedAsset.retailer, 0, 800, 800, 800, 1, coating, selectedAsset.stretchability, selectedAsset.customization, URL);
            break;
          case _ASSET_TYPE.DECORATED_WALLS:
            // Create the arrangement object to pass to the DragComponentState
            entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id, selectedAsset.wallType._id, selectedAsset.manufacturer, selectedAsset.retailer, 0, 800, 800, 800, 1, coating, selectedAsset.stretchability, selectedAsset.customization, URL);
            break;
        }
        break;
    }
    entity.name = selectedAsset.name;

    // Do we have to add handles to the arrangement object ?
    var addHandles = false;

    // If the assets has zones
    if (selectedAsset.zones) {
      // Add zones as child of entity
      for (i = 0; i < selectedAsset.zones.length; i++) {
        var zoneDatas = selectedAsset.zones[i];
        var length = Math.abs(zoneDatas.max.x - zoneDatas.min.x);
        var width = Math.abs(zoneDatas.max.y - zoneDatas.min.y);
        var height = Math.abs(zoneDatas.max.z - zoneDatas.min.z);
        var newZone = Savane.EntityFactory.createArrangementZone(parseInt(zoneDatas.type, 10), width, length, height, zoneDatas.offset, zoneDatas.up, zoneDatas.forward, zoneDatas.right);
        if (zoneDatas.data && zoneDatas.data != null) {
          newZone.data = zoneDatas.data;
        }
        entity.addChild(newZone);
        var position = Savane.math.vec3.create();
        // We retrieve the position of the box thanks to min and max. Offset acts as an anchor now.
        //Savane.math.vec3.set(position, zoneDatas.offset.x, zoneDatas.offset.y, zoneDatas.offset.z);
        Savane.math.vec3.set(position, zoneDatas.min.x + (zoneDatas.max.x - zoneDatas.min.x) / 2, zoneDatas.min.y + (zoneDatas.max.y - zoneDatas.min.y) / 2, zoneDatas.min.z + (zoneDatas.max.z - zoneDatas.min.z) / 2);
        newZone.localPosition = position;

        // If the zone is for handles, then we will have to create them right after this loop
        if (newZone.isHandleZone()) {
          addHandles = true;
        }
      }
    }

    // If the asset has a sketchblock
    if (selectedAsset.sketchBlock !== null && selectedAsset.sketchBlock !== undefined) {
      // Add sketchblock as child of entity
      var sketchBlock = Savane.EntityFactory.createSketchBlock(selectedAsset.sketchBlock._id, selectedAsset.sketchBlock.dimensions.width, selectedAsset.sketchBlock.dimensions.length, selectedAsset.sketchBlock.dimensions.height);
      entity.addChild(sketchBlock);

      //FIXME *****Temporary decal using a predefined rules
      var position = Savane.math.vec3.create();
      switch (selectedAsset.objectType._id) {
        case Savane.SceneConstants.ArrangementType.kitchenFurnitureBottom:
        case Savane.SceneConstants.ArrangementType.kitchenFurnitureColumn:
          Savane.math.vec3.set(position, 0, (entity.width - sketchBlock.width) * 0.5, (-entity.height + sketchBlock.height) * 0.5); //Back-bottom
          break;
        case Savane.SceneConstants.ArrangementType.kitchenFurnitureTop:
          Savane.math.vec3.set(position, 0, (entity.width - sketchBlock.width) * 0.5, (entity.height - sketchBlock.height) * 0.5); //Back-top
          break;
      }
      //*******
      var position = Savane.math.vec3.create();
      Savane.math.vec3.set(position, 0, (sketchBlock.width - entity.width) / 2, 0);
      sketchBlock.transform.localPosition = position;
    }
    entity._objectTypeConfig = selectedAsset.objectTypeConfig;

    // Config to parse
    var config;
    // Property within the config
    var prop;

    // Retrieve config from objectType attach to the asset
    if (selectedAsset.objectType !== undefined) {
      config = selectedAsset.objectType.config;
    }
    if (config === undefined) {
      config = {};
    }
    if (selectedAsset.objectType !== undefined) {
      //Read super conf to file empty params
      for (prop in selectedAsset.objectType.superConfig) {
        if (config[prop] === undefined || config[prop] === null) {
          config[prop] = selectedAsset.objectType.superConfig[prop];
        }
      }
    }

    // Proceed configuration by parsing all config properties in the array
    for (prop in config) {
      if (config[prop] !== null) {
        switch (prop) {
          // Object is anchored
          case 'anchor':
            var newAnchor = Savane.math.vec3.create();
            Savane.math.vec3.set(newAnchor, config[prop].x, config[prop].y, config[prop].z);
            entity.anchor = newAnchor;
            break;

          // Object can be stacked
          case 'stackable':
            entity.stackable = config[prop];
            break;

          // Floor height parameter
          case 'height':
            // Very ugly, in case anchor is active and anchor y === -1 the floor height of the object is determined from the property
            if (config.anchor && config.anchor.y < 0) {
              switch (config.anchor.y) {
                // middle
                case -1:
                  entity.floorHeight = config[prop] * 10 - entity.height / 2;
                  break;

                // bottom at
                case -2:
                  entity.floorHeight = config[prop] * 10;
                  break;

                // top at
                case -3:
                  entity.floorHeight = config[prop] * 10 - entity.height;
                  break;
              }
            } else {
              // Else we create a box collider instead

              // Check if the object already has a box collider
              var zones = entity.getZones(Savane.SceneConstants.ArrangementZoneType.collider);

              // No box ?
              if (zones.length <= 0) {
                // zone height
                var zoneHeight = config[prop] * 10;
                // Create a zone, height of the zone is in the parameter (this will create a collision box from the floor to a specific height)
                var zone = Savane.EntityFactory.createArrangementZone(Savane.SceneConstants.ArrangementZoneType.collider, entity.width, entity.length, Math.abs(zoneHeight));
                // Decal zone
                var vecPos = Savane.math.vec3.create();
                // yPos of the zone bottom of the object as default
                var yPos = (Math.abs(zoneHeight) - entity.height) * 0.5;

                // If negative height then the box is at the top of the object
                if (zoneHeight < 0) {
                  yPos = -yPos;
                }

                // Create the position of the collider box
                Savane.math.vec3.set(vecPos, 0, 0, yPos);
                zone.localPosition = vecPos;
                // Add to entity
                entity.addChild(zone);
              }
            }
            break;
        }
      }
    }
    if (assetType === _ASSET_TYPE.ARRANGEMENTS && selectedAsset.type !== 'asset') {
      loadExtraAssets = false;
    }

    // Do we have to load extra assets linked to the current asset ? (handles, coatings)
    if (loadExtraAssets) {
      // We will force a serie of async tasks to be synced
      async.series([
      // Step one, deal with customizations if any
      function (serialCallback) {
        if (entity.customization !== undefined) {
          async.each(entity.customization.parts, function (item, customizationCallback) {
            AssetManagerServices.getAsset(AssetManagerServices._ASSET_TYPE.COATINGS, item.id_coating, null, function (coatingAsset) {
              if (!coatingAsset._id) {
                // asset manager is down, we receive an empty data dict
                return customizationCallback();
              }
              // Create new coating to apply for this customization part of the kitchen furniture
              var hangType = Savane.Coating.HangType.door;
              if (item.name.includes("Porte")) {
                hangType = Savane.Coating.HangType.door;
              }
              if (item.name.includes("Caisson")) {
                hangType = Savane.Coating.HangType.box;
              }
              if (item.name.includes("Gouttiere")) {
                hangType = Savane.Coating.HangType.gutter;
              }
              if (item.name.includes("HautBas")) {
                hangType = Savane.Coating.HangType.topBottom;
              }
              if (item.name.includes("Evier")) {
                hangType = Savane.Coating.HangType.sink;
              }
              if (item.name.includes("Mitigeur")) {
                hangType = Savane.Coating.HangType.mixer;
              }
              if (item.name.includes("Panneau")) {
                hangType = Savane.Coating.HangType.panel;
              }
              if (item.name.includes("Etagere")) {
                hangType = Savane.Coating.HangType.shelf;
              }
              if (item.name.includes("Fond")) {
                hangType = Savane.Coating.HangType.back;
              }
              if (item.name.includes("Chant")) {
                hangType = Savane.Coating.HangType.front;
              }
              if (item.name.includes("Bouton")) {
                hangType = Savane.Coating.HangType.button;
              }
              if (item.name.includes("Plaque")) {
                hangType = Savane.Coating.HangType.flatpiece;
              }
              var coatingComponent;
              if (coatingAsset.colors !== undefined) {
                coatingComponent = new Savane.Coating(coatingAsset._id, coatingAsset.manufacturer, coatingAsset.retailer, hangType, [coatingAsset.colors], coatingAsset.randomization, coatingAsset.isHpFloorGenerator ? coatingAsset.hpFloorGeneratorSettings : undefined);
              } else {
                // Old structure with colors in configs
                coatingComponent = new Savane.Coating(coatingAsset._id, coatingAsset.manufacturer, coatingAsset.retailer, hangType, coatingAsset.configs, coatingAsset.randomization, coatingAsset.isHpFloorGenerator ? coatingAsset.hpFloorGeneratorSettings : undefined);
              }
              entity.addComponent(coatingComponent);
              customizationCallback();
            }.bind(this));
          }, function (err) {
            serialCallback(null, null);
          });
        } else {
          serialCallback(null, null);
        }
      },
      // Step two, load plinth coating to attach it to the entity if the object has plinthes
      function (serialCallback) {
        // Load plinth coating if necessary
        if (AssetManagerServices.isKitchenEntityWithPlinths(entity) && typeof EntityDefaultPropertiesManager !== 'undefined') {
          AssetManagerServices.getAsset(AssetManagerServices._ASSET_TYPE.COATINGS, EntityDefaultPropertiesManager.getInstance().getKitchenDefaultCoating("Plinthes"), null, function (coatingAsset) {
            // Create new coating to apply for plinths of the kitchen furniture
            var coatingComponent;
            if (coatingAsset.colors !== undefined) {
              coatingComponent = new Savane.Coating(coatingAsset._id, coatingAsset.manufacturer, coatingAsset.retailer, Savane.Coating.HangType.kitchenplinth, [coatingAsset.colors], coatingAsset.randomization, coatingAsset.isHpFloorGenerator ? coatingAsset.hpFloorGeneratorSettings : undefined);
            } else {
              coatingComponent = new Savane.Coating(coatingAsset._id, coatingAsset.manufacturer, coatingAsset.retailer, Savane.Coating.HangType.kitchenplinth, coatingAsset.configs, coatingAsset.randomization, coatingAsset.isHpFloorGenerator ? coatingAsset.hpFloorGeneratorSettings : undefined);
            }
            entity.addComponent(coatingComponent);
            serialCallback(null, null);
          }.bind(this));
        } else {
          serialCallback(null, null);
        }
      },
      // Step three, load handle object if the entity has handles
      function (serialCallback) {
        // Do we have to add handles
        if (addHandles && typeof EntityDefaultPropertiesManager !== 'undefined') {
          // Handle asset to create, get it from default properties first
          var handleModeleId = EntityDefaultPropertiesManager.getInstance().kitchenDefaultHandle;
          // Handle side will be center as default
          var handleSide = selectedAsset.handleSide !== undefined ? selectedAsset.handleSide : 0;

          // If there is a handle modele
          if (handleModeleId !== '') {
            // Load it
            AssetManagerServices.getAsset(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, handleModeleId, null, function (handleAsset) {
              var handleModele = handleAsset;
              if (handleModele !== null) {
                // Create an entity thanks to this loaded model
                AssetManagerServices.createAssetEntity(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, handleModele, true, function (handleEntity) {
                  // Populate the arrangement with the loaded handle
                  entity.populateWithHandles(handleEntity, handleSide);
                  serialCallback(null, null);
                }.bind(this));
              } else {
                serialCallback(null, null);
              }
            }.bind(this));
          } else {
            // Default initialisation if no handle
            entity.handleSide = handleSide;
            entity.handleAsset = handleModeleId;
            serialCallback(null, null);
          }
        } else {
          serialCallback(null, null);
        }
      },
      // Final step, call the top level callback to terminate the loading
      function (serialCallback) {
        // Fire creation event
        Savane.eventsManager.dispatch(EVENTS.ARRANGEMENT_CREATED, entity);

        // Call callback when done to inform entity created
        callback(entity);
        serialCallback(null, null);
      }], function (err, results) {});
    } else {
      if (assetType === _ASSET_TYPE.ARRANGEMENTS) {
        switch (selectedAsset.type) {
          case 'composition':
            var groupEntity = Savane.EntityFactory.createEmptyArrangementGroup();
            groupEntity.arrangementGroupType = selectedAsset.type;
            var totalLength = 0;
            var totalHeight = 0;
            for (var i = 0; i < selectedAsset.configs.length; i++) {
              totalLength += selectedAsset.configs[i].dimensions.width;
              totalHeight += selectedAsset.configs[i].dimensions.height;
            }
            var pos;
            if (entity.anchor[2] !== 1) {
              pos = -totalLength / 2;
            } else {
              pos = totalHeight / 2;
            }
            for (var i = 0; i < selectedAsset.configs.length; i++) {
              var clonedEntity = Savane.EntityFactory.cloneArrangementObject(entity, true);
              var URL = getMediaArrangementUrl() + selectedAsset._id + '/medias/previews/top_view' + (i + 1) + '.webp';
              var comp = clonedEntity.getComponent(Savane.ComponentConstants.ComponentType.RendererTopView);
              comp.urlTopview = URL;
              clonedEntity.colorId = i + 1;
              if (entity.anchor[2] !== 1) {
                clonedEntity.transform.localMatrix[12] = pos + selectedAsset.configs[i].dimensions.width / 2;
                pos += selectedAsset.configs[i].dimensions.width;
              } else {
                clonedEntity.transform.localMatrix[14] = pos - selectedAsset.configs[i].dimensions.height / 2;
                pos -= selectedAsset.configs[i].dimensions.height;
              }
              if (clonedEntity._originalLength) {
                clonedEntity._originalLength = selectedAsset.configs[i].dimensions.width;
              }
              if (clonedEntity._originalWidth) {
                clonedEntity._originalWidth = selectedAsset.configs[i].dimensions.length;
              }
              if (clonedEntity._originalHeight) {
                clonedEntity._originalHeight = selectedAsset.configs[i].dimensions.height;
              }
              clonedEntity._length = selectedAsset.configs[i].dimensions.width;
              clonedEntity._width = selectedAsset.configs[i].dimensions.length;
              clonedEntity._height = selectedAsset.configs[i].dimensions.height;
              groupEntity.addChild(clonedEntity);
            }
            groupEntity.recenter();
            entity = groupEntity;
            break;
        }
      }

      // Fire creation event
      Savane.eventsManager.dispatch(EVENTS.ARRANGEMENT_CREATED, entity);

      // Call callback when done to inform entity created
      callback(entity);
    }
  }
};
var getTemplates = exports.getTemplates = function getTemplates(styles, roomtypes, stringFilters, page, limit, callback) {
  var url = ARRMGR_URL + 'api/v1/templates?' + ARRMGR_API_KEY + stringFilters;
  if (styles.length > 0) {
    url += '&q.styles.in=' + styles;
  }
  if (roomtypes.length > 0) {
    url += '&q.roomTypes.in=' + roomtypes;
  }
  Savane.HttpService.get(url + '&page=' + page + '&limit=' + limit).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var getPinboards = exports.getPinboards = function getPinboards(pub, email, page, limit, callback) {
  var url = ARRMGR_URL + 'api/v1/pinboards?' + ARRMGR_API_KEY;
  if (pub) {
    url += '&q.public.eq=true';
  } else {
    url += '&q.owner.email.eq=' + email;
  }
  if (page && limit) {
    url += '&sort=name&page=' + page + '&limit=' + limit;
  }
  Savane.HttpService.get(url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var createPinboard = exports.createPinboard = function createPinboard(email, name, pub, callback) {
  var userIdUrl = ARRMGR_URL + 'services/users/?' + ARRMGR_API_KEY + '&q.email.eq=' + email + "&fields=_id";
  Savane.HttpService.get(userIdUrl).then(function (response) {
    var url = ARRMGR_URL + 'api/v1/pinboards?' + ARRMGR_API_KEY;
    var payload = {
      'name': name,
      'owner': response.data.resources[0]._id,
      'public': pub
    };
    Savane.HttpService.post(url, payload).then(function (response) {
      callback(response.data);
    }, function () {
      callback(null);
    });
  }, function () {
    callback(null);
  });
};
var editPinboard = exports.editPinboard = function editPinboard(id, name, callback) {
  var url = ARRMGR_URL + 'api/v1/pinboards/' + id + '?' + ARRMGR_API_KEY;
  var payload = {
    'name': name
  };
  Savane.HttpService.patch(url, payload).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var deletePinboard = exports.deletePinboard = function deletePinboard(id, callback) {
  var url = ARRMGR_URL + 'api/v1/pinboards/' + id + '?' + ARRMGR_API_KEY;
  Savane.HttpService["delete"](url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var getResourcesPinboards = exports.getResourcesPinboards = function getResourcesPinboards(idsListStr, email, callback) {
  Savane.eventsManager.dispatch(Savane.Events.STOP_UPDATING_ENVS);
  var url = AssetManagerServices.getUrl() + 'api/v1/pinboards?q.items.in=' + idsListStr + '&q.owner.email.eq=' + email;
  url += '&' + ARRMGR_API_KEY;
  Savane.HttpService.get(url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var addToPinboard = exports.addToPinboard = function addToPinboard(asset, pinboard, callback) {
  Savane.eventsManager.dispatch(Savane.Events.STOP_UPDATING_ENVS);
  var url = AssetManagerServices.getUrl() + 'api/v1/pinboards/' + pinboard + '/assets/' + asset + '?' + ARRMGR_API_KEY;
  Savane.HttpService.put(url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var removeFromPinboard = exports.removeFromPinboard = function removeFromPinboard(asset, pinboard, callback) {
  Savane.eventsManager.dispatch(Savane.Events.STOP_UPDATING_ENVS);
  var url = AssetManagerServices.getUrl() + 'api/v1/pinboards/' + pinboard + '/assets/' + asset + '?' + ARRMGR_API_KEY;
  Savane.HttpService["delete"](url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var getAssetsGroupsLists = exports.getAssetsGroupsLists = function getAssetsGroupsLists(pub, email, page, limit, callback) {
  var url = ARRMGR_URL + 'api/v1/assetsGroupsLists?' + ARRMGR_API_KEY;
  if (pub) {
    url += '&q.public.eq=true';
  } else {
    url += '&q.owner.email.eq=' + email;
  }
  if (page && limit) {
    url += '&sort=name&page=' + page + '&limit=' + limit;
  }
  Savane.HttpService.get(url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var isUserAllowedToManageAssetsGroupsList = exports.isUserAllowedToManageAssetsGroupsList = function isUserAllowedToManageAssetsGroupsList(email, callback) {
  var user = ARRMGR_URL + 'services/users/?' + ARRMGR_API_KEY + '&q.email.eq=' + email + '&q.groups.in=58c137ca087d8449000f53e2&fields=_id';
  Savane.HttpService.get(user).then(function (response) {
    callback(response.status === 200);
  }, function () {
    callback(null);
  });
};
var createAssetsGroupsList = exports.createAssetsGroupsList = function createAssetsGroupsList(email, name, pub, callback) {
  var userIdUrl = ARRMGR_URL + 'services/users/?' + ARRMGR_API_KEY + '&q.email.eq=' + email + "&fields=_id";
  Savane.HttpService.get(userIdUrl).then(function (response) {
    var url = ARRMGR_URL + 'api/v1/assetsGroupsLists?' + ARRMGR_API_KEY;
    var payload = {
      'name': name,
      'owner': response.data.resources[0]._id,
      'public': pub
    };
    Savane.HttpService.post(url, payload).then(function (response) {
      callback(response.data);
    }, function () {
      callback(null);
    });
  }, function () {
    callback(null);
  });
};
var editAssetsGroupsList = exports.editAssetsGroupsList = function editAssetsGroupsList(id, name, callback) {
  var url = ARRMGR_URL + 'api/v1/assetsGroupsLists/' + id + '?' + ARRMGR_API_KEY;
  var payload = {
    'name': name
  };
  Savane.HttpService.patch(url, payload).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var deleteAssetsGroupsList = exports.deleteAssetsGroupsList = function deleteAssetsGroupsList(id, callback) {
  var url = ARRMGR_URL + 'api/v1/assetsGroupsLists/' + id + '?' + ARRMGR_API_KEY;
  Savane.HttpService["delete"](url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var getResourcesAssetsGroupsList = exports.getResourcesAssetsGroupsList = function getResourcesAssetsGroupsList(idsListStr, email, callback) {
  Savane.eventsManager.dispatch(Savane.Events.STOP_UPDATING_ENVS);
  var url = AssetManagerServices.getUrl() + 'api/v1/assetsGroupsLists?q.items.in=' + idsListStr; // + '&q.owner.email.eq=' + email;
  url += '&' + ARRMGR_API_KEY;
  Savane.HttpService.get(url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var addToAssetsGroupsList = exports.addToAssetsGroupsList = function addToAssetsGroupsList(asset, assetsGroupsLists, callback) {
  Savane.eventsManager.dispatch(Savane.Events.STOP_UPDATING_ENVS);
  var url = AssetManagerServices.getUrl() + 'api/v1/assetsGroupsLists/' + assetsGroupsLists + '/assetsGroups/' + asset + '?' + ARRMGR_API_KEY;
  Savane.HttpService.put(url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var removeFromAssetsGroupsList = exports.removeFromAssetsGroupsList = function removeFromAssetsGroupsList(asset, assetsGroupsLists, callback) {
  Savane.eventsManager.dispatch(Savane.Events.STOP_UPDATING_ENVS);
  var url = AssetManagerServices.getUrl() + 'api/v1/assetsGroupsLists/' + assetsGroupsLists + '/assetsGroups/' + asset + '?' + ARRMGR_API_KEY;
  Savane.HttpService["delete"](url).then(function (response) {
    callback(response.data);
  }, function () {
    callback(null);
  });
};
var replaceWithPopulatedObject = function replaceWithPopulatedObject(oldEntity, arrayLayouts, index, oldObjects, newObjectsAndParents) {
  var group;

  // Ancien format à supprimer
  if (!arrayLayouts[index].format) {
    group = Savane.EntityFactory.createArrangementGroupFromJSON(arrayLayouts[index]);
  } else {
    // Nouveau format a garder
    group = Savane.EntityFactory.createArrangementGroupFromJSON(arrayLayouts[index].format);
  }
  group.AMLayoutId = arrayLayouts[index]._id;

  // Keep track of the original object ID (to be able to find it in the created group)
  if (oldEntity.masterObjectId) {
    group.masterObjectId = oldEntity.masterObjectId;
  } else {
    group.masterObjectId = oldEntity.objectId;
  }
  // Find master object into previous entity
  var srcMasterObjectIndex = -1;
  for (var i = 0; i < oldEntity.children.length; i++) {
    if (oldEntity.children[i].objectId === group.masterObjectId) {
      srcMasterObjectIndex = i;
      break;
    }
  }

  // Find master object into new group
  var destMasterObjectIndex = -1;
  for (var i = 0; i < group.children.length; i++) {
    if (group.children[i].objectId === group.masterObjectId) {
      destMasterObjectIndex = i;
      break;
    }
  }

  // Keep original entity id into new created group
  if (srcMasterObjectIndex >= 0) {
    if (destMasterObjectIndex >= 0) {
      group.children[destMasterObjectIndex]._id = oldEntity.children[srcMasterObjectIndex]._id;
    }
  } else {
    if (destMasterObjectIndex >= 0) {
      group.children[destMasterObjectIndex]._id = oldEntity._id;
    }
  }
  // End override entity id

  // Copy customizations is any
  var oldEntityCustomization = oldEntity.customization;
  // No customization so deep search because oldEntity might be an arrangement group
  if (!oldEntityCustomization && srcMasterObjectIndex >= 0) {
    oldEntityCustomization = oldEntity.children[srcMasterObjectIndex].customization;
  }
  // If a customization is available, let's clone it
  if (oldEntityCustomization && destMasterObjectIndex >= 0) {
    group.children[destMasterObjectIndex].customization = JSON.parse(JSON.stringify(oldEntityCustomization));
  }
  // Copy customization coatings
  var oldEntityCoatings = oldEntity.getComponents(Savane.ComponentConstants.ComponentType.Coating);
  // No coating so deep search because oldEntity might be an arrangement group
  if (oldEntityCoatings.length === 0 && srcMasterObjectIndex >= 0) {
    oldEntityCoatings = oldEntity.children[srcMasterObjectIndex].getComponents(Savane.ComponentConstants.ComponentType.Coating);
  }
  // Assign coatings to object into the group
  if (destMasterObjectIndex >= 0) {
    for (var i = 0; i < oldEntityCoatings.length; i++) {
      group.children[destMasterObjectIndex].addComponent(oldEntityCoatings[i].clone());
    }
  }
  // If there are children, copie them
  var oldEntityChildren = oldEntity.children;
  if (srcMasterObjectIndex >= 0) {
    oldEntityChildren = oldEntity.children[srcMasterObjectIndex].children;
  }
  // Assign children to object into the group
  if (destMasterObjectIndex >= 0) {
    for (var i = 0; i < oldEntityChildren.length; i++) {
      group.children[destMasterObjectIndex].addChild(Savane.EntityFactory.cloneEntity(oldEntityChildren[i], true));
    }
  }
  // Copy special parameters into dest entity
  var oldEntityHandleSide = oldEntity.handleSide;
  var oldEntityHandleAsset = oldEntity.handleAsset;
  if (srcMasterObjectIndex >= 0) {
    oldEntityHandleSide = oldEntity.children[srcMasterObjectIndex].handleSide;
    oldEntityHandleAsset = oldEntity.children[srcMasterObjectIndex].handleAsset;
  }
  if (destMasterObjectIndex >= 0) {
    group.children[destMasterObjectIndex].handleSide = oldEntityHandleSide;
    group.children[destMasterObjectIndex].handleAsset = oldEntityHandleAsset;
  }
  // To remove original object scale
  var res = Savane.math.vec3.create();
  Savane.math.vec3.set(res, 1, 1, 1);
  // Copy original object matrix
  if (srcMasterObjectIndex >= 0) {
    Savane.math.mat4.copy(group.transform.localMatrix, oldEntity.children[srcMasterObjectIndex].transform.globalMatrix);
  } else {
    Savane.math.mat4.copy(group.transform.localMatrix, oldEntity.transform.globalMatrix);
  }
  // remove original object scale if any
  group.transform.localScale = res;
  // Save group altervatives
  group.masterObjectLayouts = arrayLayouts;
  // Save current alternative number
  group.masterObjectCurrentLayout = index;
  group.recenter();
  oldObjects.push(oldEntity);
  newObjectsAndParents.push({
    entity: group,
    parent: oldEntity.parent
  });
};
/*
var getRoomString(roomId) {
    str = '';

    switch(roomId) {
        case Savane.ComponentConstants.Functionalities.Diningroom:
            str += '' + roomId + ',' + Savane.ComponentConstants.Functionalities.LivingDiningroom;
            break;

        case Savane.ComponentConstants.Functionalities.Livingroom:
            str += '' + roomId + ',' + Savane.ComponentConstants.Functionalities.LivingDiningroom;
            break;

        case Savane.ComponentConstants.Functionalities.LivingDiningroom:
            str += '' + roomId + ',' + Savane.ComponentConstants.Functionalities.Livingroom + ',' + Savane.ComponentConstants.Functionalities.Diningroom;
            break;

        case Savane.ComponentConstants.Functionalities.Balcony:
        case Savane.ComponentConstants.Functionalities.Terrace:
            str += '' + Savane.ComponentConstants.Functionalities.Balcony + ',' + Savane.ComponentConstants.Functionalities.Terrace;
            break;

        case Savane.ComponentConstants.Functionalities.Bedroom:
        case Savane.ComponentConstants.Functionalities.ParentalSuite:
            str += '' + Savane.ComponentConstants.Functionalities.Bedroom + ',' + Savane.ComponentConstants.Functionalities.ParentalSuite;
            break;

        case Savane.ComponentConstants.Functionalities.Laundryroom:
        case Savane.ComponentConstants.Functionalities.Cellar:
            str += '' + Savane.ComponentConstants.Functionalities.Laundryroom + ',' + Savane.ComponentConstants.Functionalities.Cellar;
            break;

        case Savane.ComponentConstants.Functionalities.Dressing:
        case Savane.ComponentConstants.Functionalities.Wardrobe:
            str += '' + Savane.ComponentConstants.Functionalities.Dressing + ',' + Savane.ComponentConstants.Functionalities.Wardrobe;
            break;

        default:
            str += '' + roomId;
    }

    return(str);
};*/

var loadPopulatedObjects = exports.loadPopulatedObjects = function loadPopulatedObjects(entity, oldObjects, newObjectsAndParents, callback) {
  var url = AssetManagerServices.getUrl() + 'api/v1/layouts?q.master.eq=' + entity.objectId;

  //if (AssetManagerServices.isForB2B()) {
  url += '&unrealOnly=1';
  //}

  if (entity.stretchability) {
    url += '&q.dimensions.width.bt=' + (entity.length - 50) + ',' + (entity.length + 50) + '&q.dimensions.length.bt=' + (entity.width - 50) + ',' + (entity.width + 50) + '&q.dimensions.height.bt=' + (entity.height - 3) + ',' + (entity.height + 3);
  } else {
    url += '&q.dimensions.width.bt=' + (entity.length - 1) + ',' + (entity.length + 1) + '&q.dimensions.length.bt=' + (entity.width - 1) + ',' + (entity.width + 1) + '&q.dimensions.height.bt=' + (entity.height - 1) + ',' + (entity.height + 1);
  }
  // Add B2B and room infos
  /*if (AssetManagerServices.isForB2B()) {
      url += '&q.market.eq=B2B';
  }
  else {
      url += '&q.market.eq=B2C';
  }
  var room = entity.room;
  if (room) {
      var comp = room.getComponent(Savane.ComponentConstants.ComponentType.Functionality);
      if (comp && comp.functionalityId) {
          var str = getRoomString(comp.functionalityId);
            if (str !== '') {
              url += '&q.roomType.in=' + getRoomString(comp.functionalityId);
          }
      }
  }*/
  url += '&format=genericsAndReferences&apikey=' + AssetManagerServices.getToken();
  Savane.HttpService.get(url).then(function (response) {
    //success
    if (response.status === 200 && response.data && response.data.resources && response.data.resources.length > 0) {
      replaceWithPopulatedObject(entity, response.data.resources, 0, oldObjects, newObjectsAndParents);
    }
    var displayWarning = false;
    if (response.status === 204) {
      displayWarning = true;
    }
    callback(displayWarning);
  }.bind(this), function (response) {
    // failure
    callback(false);
  });
};
var switchPopulatedObjects = exports.switchPopulatedObjects = function switchPopulatedObjects(entity, add, oldObjects, newObjectsAndParents, callback) {
  var displayWarning = false;
  if (entity.masterObjectLayouts !== undefined) {
    if (entity.masterObjectLayouts.length > 1) {
      var configNb = entity.masterObjectCurrentLayout + add;
      if (configNb >= entity.masterObjectLayouts.length) {
        configNb = 0;
      }
      if (configNb < 0) {
        configNb = entity.masterObjectLayouts.length - 1;
      }
      replaceWithPopulatedObject(entity, entity.masterObjectLayouts, configNb, oldObjects, newObjectsAndParents);
    } else {
      displayWarning = true;
    }
  }
  callback(displayWarning);
};
var getSwitchSuggestions = exports.getSwitchSuggestions = function getSwitchSuggestions(assetArray, callback) {
  var url = AssetManagerServices.getUrl() + 'api/v1/assets/recommended?limit=50&apikey=' + AssetManagerServices.getToken();
  var payload = {
    'ids': assetArray,
    'types': ["608fc584d1d03b512250bb33"],
    'method': "avg"
  };
  Savane.HttpService.post(url, payload).then(function (response) {
    callback(response);
  }.bind(this), function (response) {
    callback(null);
  });
};

},{}]},{},[1])(1)
});
